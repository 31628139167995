import { gql } from '@apollo/client';

export const fragments = {
  CATEGORY_ATTRIBUTES: gql`
    fragment categoryAttributes on Category {
      id
      name
      parent {
        id
        name
      }
      tags {
        id
        name
        weight
      }
      places {
        count
        edges {
          node {
            id
            name
            designation
            parentSite {
              id
              name
            }
            parentBuilding {
              id
              name
            }
            parentFloor {
              id
              name
            }
            network {
              id
              name
            }
          }
        }
      }
    }
  `,
  CATEGORY_GROUP_ATTRIBUTES: gql`
    fragment categoryGroupAttributes on Category {
      id
      name
      children {
        count
        edges {
          node {
            id
            name
            places {
              count
            }
            tags {
              id
              name
            }
          }
        }
      }
    }
  `,
  KIOSK_ATTRIBUTES: gql`
    fragment kioskAttributes on Kiosk {
      x
      y
      id
      name
      assignedId
      progress
      site {
        id
        name
      }
      building {
        id
        name
        children {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      floor {
        id
        name
      }
      network {
        id
      }
      banner {
        interval
      }
      directory {
        progress
        tiles {
          assignedId
          name
          type
          progress
          order
          icon {
            id
            url(protocol: secure)
          }
          backgroundColor
          ... on KioskDirectoryTileFreeForm {
            title
            description
          }
          ... on KioskDirectoryTilePOIGroup {
            poiItems {
              poi {
                id
              }
              order
            }
          }
        }
      }
    }
  `,
  KIOSK_TEMPLATE_ATTRIBUTES: gql`
    fragment kioskTemplateAttributes on KioskTemplate {
      id
      progress
      screen {
        ctaText
        ctaImage {
          url(protocol: secure)
          name
          id
        }
        actionUrl
        networkLogo {
          url(protocol: secure)
          name
          id
        }
        supportImage {
          url(protocol: secure)
          name
          id
        }
        backgroundColor
        searchPlaceholderText
      }
      screensaver {
        ctaText
        backgroundColor
        networkLogo {
          url(protocol: secure)
          name
          id
        }
      }
    }
  `,
  NETWORK_BANNNER_MESSAGE_ATTRIBUTES: gql`
    fragment networkBannerMessageAttributes on NetworkBannerMessage {
      createdAt
      id
      title
      subtitle
      buttonLabel
      backgroundColor
      backgroundImage {
        id
        url (protocol: secure)
      }
      backgroundImageES {
        id
        url (protocol: secure)
      }
      backgroundImageAccessibilityText
      status
      theme
      actionLink {
        type
        data
        categories {
          id
          name
        }
      }
    }
  `,
  NETWORK_NAVIGATION_ATTRIBUTES: gql`
    fragment networkNavigationAttributes on NetworkNavigation {
      id
      validation {
        status
        errors {
          message
          path
        }
      }
      fillColor
      selectedColor
      tabs {
        id
        name
        type
        data
        order
        curatedList {
          id
          name
        }
        categories {
          id
          name
        }
        icon {
          url(protocol: secure)
          image {
            id
          }
        }
        destination {
          id
          type
          label
        }
        visibilityRule {
          id
          name
        }
        visibilityRuleCustom
      }
      items {
        id
        name
        type
        data
        order
        curatedList {
          id
          name
        }
        categories {
          id
          name
        }
        destination {
          id
          type
          label
        }
        icon {
          url(protocol: secure)
          image {
            id
            name
          }
        }
        legalInfo {
          eulaUrl
          privacyPolicyUrl
          legalNotesUrl
        }
        supportingImage {
          en {
            id
            url(protocol: secure)
            name
          }
          es {
            id
            url(protocol: secure)
            name
          }
        }
        supportingImageAccessibilityText
        visibilityRule {
          id
          name
        }
        visibilityRuleCustom
      }
    }

  `,
  NETWORK_CONFIGURATION_VERSION_ATTRIBUTES: gql`
    fragment networkConfigurationVersionAttributes on Network {
      id
      configuration {
        id
        iosControl {
          notSupported {
            version
            message
          }
          endOfLife {
            version
            message
          }
        }
        androidControl {
          notSupported {
            version
            message
          }
          endOfLife {
            version
            message
          }
        }
      }
    }
  `,
  NETWORK_CONFIGURATION_FEATURES: gql`
    fragment networkConfigurationFeatures on NetworkConfigurationFeatures {
        appointmentAvailability
        banners
        cycleCard
        indoorMobileWayfinding
        kiosk
        languages
        myChart
        myFavorites
        networkDashboardNavlinks
        networkDashboardUnderlay
        openPlatform
        personas
        providerIntegration
        pushNotifications
        pushNotificationVisibilityRules
        quicklinkList
        singleSignOn
    }
  `,
  NETWORK_PLACE_ATTRIBUTES: gql`
    fragment networkPlaceAttributes on NetworkPlace {
      id
      name
      shortName
      description
      designation
      departmentIds
      externalId
      buildings: descendants(where: { designation: "building" }) {
        edges {
          node {
            id
            name
            externalId
            indoorLocation {
              mapId
            }
            validation {
              status
            }
            isMapped
            geoLocation {
              address {
                street
                city
              }
            }
            categories {
              id
              name
            }
            floors: children(where: { designation: "floor" }) {
              count
            }
            pois: descendants(where: { designation: "poi" }) {
              count
            }
            landmarks: descendants(where: { designation: "landmark" }) {
              count
            }
            navigation {
              defaultPlace {
                name
              }
            }
          }
        }
      }
      floors: descendants(where: { designation: "floor" }) {
        edges {
          node {
            id
            name
            shortName
            designation
            externalId
            indoorLocation {
              mapId
            }
            validation {
              status
            }
            isMapped
            pois: children(where: { designation: "poi" }) {
              count
            }
            landmarks: children(where: { designation: "landmark" }) {
              count
            }
          }
        }
      }
      pois: descendants(where: { designation: "poi" }) {
        edges {
          node {
            id
            name
            shortName
            designation
            externalId
            indoorLocation {
              mapKey
            }
            validation {
              status
            }
            isMapped
            geoLocation {
              address {
                street
                city
              }
            }
            categories {
              id
              name
            }
            parentSite {
              id
              name
            }
            parentBuilding {
              id
              name
            }
            parentFloor {
              id
              name
            }
          }
        }
      }
      landmarks: descendants(where: { designation: "landmark" }) {
        edges {
          node {
            id
            name
            shortName
            designation
            externalId
            indoorLocation {
              mapKey
            }
            validation {
              status
            }
            isMapped
            parentBuilding {
              name
            }
            parentFloor {
              name
            }
          }
        }
      }
      parent {
        id
        designation
        name
      }
      parentSite {
        id
        name
        designation
        isMapped
        navigation {
          defaultPlace {
            id
          }
        }
        geoLocation {
          address {
            building
            floor
            street
            suite
            city
            state
            zip
          }
          markerPoint {
            lat
            lng
          }
          navigationPoint {
            lat
            lng
          }
        }
      }
      parentBuilding {
        id
        name
        designation
        isMapped
        navigation {
          defaultPlace {
            id
          }
        }
        inheritAddress
        geoLocation {
          address {
            building
            floor
            street
            suite
            city
            state
            zip
          }
          markerPoint {
            lat
            lng
          }
          navigationPoint {
            lat
            lng
          }
        }
      }
      parentFloor {
        id
        name
        isMapped
      }
      categories {
        id
        name
        tags {
          id
          name
        }
      }
      contact {
        hours {
          type
          custom {
            day
            status
            hours {
              startTime
              endTime
            }
          }
        }
        exceptionHours {
          id
          name
          type
          custom {
            day
            status
            hours {
              startTime
              endTime
            }
          }
          startDate
          endDate
        }
      }
      excludeCategoryTags
      inheritAddress
      geoLocation {
        address {
          building
          floor
          suite
          street
          streetNumber
          city
          state
          zip
        }
        perimeter {
          radius
          points {
            lat
            lng
          }
        }
        markerPoint {
          lat
          lng
        }
        navigationPoint {
          lat
          lng
        }
      }
      isMapped
      isParkingArea
      isPromotionItem
      isCampusNavEnabled
      indoorLocation {
        mapId
        mapKey
        indoorPoint {
          x
          y
        }
        markerPoint {
          x
          y
        }
      }
      matching {
        enabled
        rule
      }
      navigation {
        arrivalImage {
          id
          name
          url(protocol: secure)
        }
        parkingType
        enableStrictParkingArrival
        enableAlternateNavigation
        defaultPlace {
          id
          name
          designation
          isMapped
          parentBuilding {
            name
          }
          parentFloor {
            name
          }
        }
        disclaimer
        reroute {
          directive
          destination {
            id
          }
          message
          image {
            url
            name
          }
        }
      }
      network {
        id
        name
        placeholders {
          id
          type
          image {
            id
            name
            url(protocol: secure)
          }
        }
        icons {
          id
          type
          image {
            id
            name
            url(protocol: secure)
          }
        }
      }
      geoIndoorTranslation {
        rotation
        geoPoint {
          lat
          lng
        }
        indoorPoint {
          x
          y
        }
      }
      media {
        id
        type
        image {
          id
          name
          url(protocol: secure)
          transformations(names: ["image-thumbnail-networkplace"]) {
            url
          }
        }
        video {
          id
          youtubeId
          thumbnail {
            id
            name
            url(protocol: secure)
          }
          url(protocol: secure)
        }
      }
      searchability
      tags {
        name
      }
      osmMapInfo {
        boundNorth
        boundSouth
        boundWest
        boundEast
      }
      actionLinks {
        type
        name
        shortName
        data
        order
        showInDashboardView
        icon {
          url(protocol: secure)
          image {
            id
            name
          }
        }
      }
      visibility
      order
      priority
      matching {
        enabled
        rule
      }
      defaultImage {
        id
        name
        url(protocol: secure)
      }
      validation {
        status
      }
      metadata {
        ui {
          autoPopulateCoordinatesFromAddress
        }
      }
      workspaces {
        name
      }
    }
  `,
  SYSTEM_NAVIGATION_ATTRIBUTES: gql`
    fragment systemNavigationAttributes on SystemNavigation {
      id
      fillColor
      selectedColor
      status
      tabs {
        id
        name
        type
        data
        order
        curatedList {
          id
          name
        }
        categories {
          id
          name
        }
        icon {
          url(protocol: secure)
          image {
            id
          }
        }
        destination {
          id
          type
          label
        }
        visibilityRule {
          id
          name
        }
        visibilityRuleCustom
      }
      items {
        id
        name
        type
        data
        order
        curatedList {
          id
          name
        }
        categories {
          id
          name
        }
        icon {
          url(protocol: secure)
          image {
            id
          }
        }
        destination {
          id
          type
          label
        }
        visibilityRule {
          id
          name
        }
        visibilityRuleCustom
      }
    }
  `,
  USER_ATTRIBUTES: gql`
    fragment userAttributes on User {
      id
      email {
        address
      }
      roles {
        name
        internal
      }
      state
      profile {
        firstName
        lastName
      }
      invitedAt
    }
  `,
};
