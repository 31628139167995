import { getExternalAppFormValidationSchema, getWebsiteValidationSchema } from 'helpers/form-util';
import { buildLanguageSchema, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import { isValidJSON, PhoneRegexp, UrlRegexp } from 'helpers/string-util';
import * as Yup from 'yup';

export const BOTTOM_NAVIGATION_LINKS = 'tabs';
export const MORE_MENU_LINKS = 'items';

export const buildNavigationTab = (values = {}, languages = []) => {
  let navTab = {};

  switch (values.linkType) {
    case 'category':
      navTab = {
        ...navTab,
        type: 'category',
        data: {
          id: values.categories?.map((c) => c.id),
        },
      };
      break;
    case 'in_app':
      navTab = {
        ...navTab,
        type: 'in_app',
        data: {
          id: values.inAppDest,
          url: null,
        },
      };
      break;
    case 'list':
      navTab = {
        ...navTab,
        type: 'list',
        data: {
          id: values.listDest,
        },
      };
      break;
    case 'external_app':
      if (
        !values.externalType
        && !values.externalURL
        && !values.externalAndroidURL
        && !values.externaliOSURL
      ) {
        return null;
      }
      navTab = {
        ...navTab,
        type: 'external_app',
        data: {
          client: values.externalType ? values.externalType : '',
          url: values.externalURL ? values.externalURL : '',
          androidUrl: values.externalAndroidURL
            ? values.externalAndroidURL
            : '',
          iosUrl: values.externaliOSURL ? values.externaliOSURL : '',
        },
      };

      if (values.hasPlatformSpecificFallbacks) {
        navTab.data.androidFallbackUrl = values.fallbackAndroidURL ?? null;
        navTab.data.iosFallbackUrl = values.fallbackiOSURL ?? null;
      } else {
        navTab.data.androidFallbackUrl = null;
        navTab.data.iosFallbackUrl = null;
      }
      break;
    case 'website':
      if (!values.webType && !values.webUrlEn) {
        return null;
      }
      navTab = {
        ...navTab,
        type: 'website',
        data: {
          client: values.webType ?? '',
          url: {
            en: values.webUrlEn ?? '',
            ...hasSpanish(languages) && {
              es: values.webUrlEs ?? '',
            },
          },
        },
      };
      break;
    case 'phone':
      if (!values.phone) {
        return null;
      }
      navTab = {
        ...navTab,
        type: 'phone',
        data: {
          phone: values.phone,
        },
      };
      break;
    case 'custom':
      if (!values.custom) {
        return null;
      }
      navTab = {
        ...navTab,
        type: 'custom',
        data: {
          url: values.custom,
        },
      };
      break;
    default:
      navTab = null;
      break;
  }

  return navTab;
};

export const getBottomNavLinkSchema = (type, languages) => {
  const nameLimit = type === BOTTOM_NAVIGATION_LINKS ? 10 : 45;
  return Yup.object().shape(
    buildLanguageSchema(
      {
        nameEn: Yup.string()
          .max(nameLimit, `Name must be ${nameLimit} characters or less`)
          .required('Name is required'),
        nameEs: Yup.string().max(
          nameLimit,
          `Name must be ${nameLimit} characters or less`,
        ),
        supportingImageAccessibilityTextEn: Yup.string()
          .nullable()
          .max(250, 'Accessibility description must be 250 characters or less'),
        supportingImageAccessibilityTextEs: Yup.string()
          .nullable()
          .max(250, 'Accessibility description must be 250 characters or less'),
        // Quicklink Validation
        linkType: Yup.string().nullable(),
        // categories:
        categories: Yup.array().when('linkType', {
          is: 'category',
          then: (schema) => schema.min(1).required('Please select at least one category.'),
        }),
        // in_app
        inAppDest: Yup.string()
          .nullable()
          .when('linkType', {
            is: 'in_app',
            then: (schema) => schema.required('In-App Destination is required.'),
          }),
        // list
        listDest: Yup.string()
          .nullable()
          .when('linkType', {
            is: 'list',
            then: (schema) => schema.required('List is required.'),
          }),
        // External App
        ...getExternalAppFormValidationSchema,
        // Website
        ...getWebsiteValidationSchema,
        // Phone
        phone: Yup.string().when(['linkType'], {
          is: 'phone',
          then: (schema) => schema
              .required('Phone Number is required')
              .matches(PhoneRegexp, 'Invalid phone number'),
        }),
        // Custom
        custom: Yup.string()
          .nullable()
          .when('linkType', {
            is: 'custom',
            then: (schema) => schema.required('This open field is required'),
          }),

        // About Us
        eulaUrl: Yup.string()
          .nullable()
          .matches(UrlRegexp, 'Invalid website url'),
        privacyPolicyUrl: Yup.string()
          .nullable()
          .matches(UrlRegexp, 'Invalid website url'),
        legalNotesUrl: Yup.string()
          .nullable()
          .matches(UrlRegexp, 'Invalid website url'),
        visibilityRuleCustom: Yup.string().nullable().when('visibilityRuleSelect', {
          is: 'custom',
          then: (schema) => schema
            .test('isJSON', 'Invalid JSON input', isValidJSON)
            .required('Visibility Rule is required'),
        }),
      },
      languages,
      {
        nameEn: LANGUAGE_CODES.ENGLISH,
        nameEs: LANGUAGE_CODES.SPANISH,
        supportingImageAccessibilityTextEn: LANGUAGE_CODES.ENGLISH,
        supportingImageAccessibilityTextEs: LANGUAGE_CODES.SPANISH,
        webUrlEn: LANGUAGE_CODES.ENGLISH,
        webUrlEs: LANGUAGE_CODES.SPANISH,
      },
    ),
  );
};

export const isAboutUsLink = (item) => item?.data?.id === 'about';
